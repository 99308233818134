@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap");
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #04021d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer components {
    .pseudoBefore {
        @apply relative z-10 before: absolute before:-z-10 before:content-[''];
    }
} */

.cardPseudo {
  position: relative;
  /* z-index: 5; */
}

.cardPseudo::after {
  content: "";
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  width: 80px;
  height: 80px;
  border-radius: 1.5rem 0 1rem 0;
  z-index: -1;
  transition: 300ms cubic-bezier(1, 0, 0, 1);
}

.cardPseudo:hover::after {
  bottom: -1.5rem;
  left: -1.5rem;
}

@keyframes shine-pulse {
  0% {
    box-shadow: 0 0 10px rgba(67, 87, 198, 0.699);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 1);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
}

.shine-pulse {
  animation: shine-pulse 2s infinite;
}


.pulse_running_border {
  
  
  text-shadow: 0 1px 0 #000;
  --border-angle: 0turn;
  --main-bg: conic-gradient(
      from var(--border-angle),
      #213,
      #112 5%,
      #112 60%,
      #213 95%
    );
  border: solid 0.15rem transparent;
  border-radius: 1em;
  --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, rgb(0, 200, 255), rgb(237,160,60) 99%, transparent);
  background: var(--main-bg) padding-box, var(--gradient-border) border-box, var(--main-bg) border-box;
  background-position: center center;
  -webkit-animation: bg-spin 5s linear infinite;
          animation: bg-spin 5s linear infinite;
}
@-webkit-keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}


@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
}


.scrollbar::-webkit-scrollbar-track {
  background: #09006c;
  /* border-radius: 5px; */
}


.scrollbar::-webkit-scrollbar-thumb {
  background: rgb(0, 114, 137);
  border-radius: 5px;
}


.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

