.label {
    color: white;
    padding-right: 10px;
    letter-spacing: .04rem;
}

.input {
    text-align: center;
    border: 1px solid grey;
    width: 250px;
    font-size: 15px;
    border-radius: 5px;
    min-height: 38px;
    /*background-color: lightgrey;*/
}

.subscribeButton {
    color: white;
    min-width: 130px;
    min-height: 38px;
    letter-spacing: .03rem;
    margin-left: 10px;
    border-radius: 10px;
    height: 28px;
    font-size: 12px;
    border: 1px solid grey;
}

.subscribeButton:hover {
    background-color: lightskyblue;
    color: #00100b;
    /*box-shadow: 0 3px #999;*/
}

.subscribeButton:disabled {
    background-color: lightgrey;
}

.subscribeButton:active {
    background-color: lightblue;
    box-shadow: 0 3px #666;
    transform: translateY(4px);
}